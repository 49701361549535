import {useContext, useEffect, useRef, useState} from "react"
import {ModalContext} from "../../../contexts"
import {toast} from "react-toastify"
import {Button, Col, Form, Input, Label, Row} from "reactstrap"
import Select from "react-select"
import {selectListData} from "../../../api/general"
import {addDocuments} from "../../../api/account"
import {useTranslation} from "react-i18next"

const UploadDocument = ({updateTable}) => {
	const {closeModal} = useContext(ModalContext)
	const unloadedFile= useRef('')
	const [selectedType, setSelectedType] = useState('')
	const [documentTypes, setDocumentTypes] = useState([])
  const { t } = useTranslation()

	const submitHandler = (e) => {
		e.preventDefault()
		const btn = e.target.querySelector('[type="submit"]')
		btn.disabled = true

		const data = new FormData()
		data.append('type', selectedType.value)

		let i = 0
		for (let file of unloadedFile.current) {
			data.append(`attachments[${i}]`, file, file.name)
			i++
		}

		addDocuments(data).then((res) => {
			closeModal()
			let message = res?.message || 'Empty response message.'

			if (res.success) {
				toast.success(res.message)
				updateTable()
			} else {
				toast.error(message)
			}
		}).catch(r => {
			toast.error(r.message)
		}).finally(() => btn.disabled = false)
	}

	useEffect(() => {
		selectListData('document_types').then(r => setDocumentTypes(r))
	}, [])

  return (
    <>
      <Form onSubmit={submitHandler}>
        <Row>
          <Col xs={12} className='mb-3'>
            <Label className="form-label mb-0">{t("Document type")}</Label>
            <Select
              placeholder={t("Choose...")}
              value={selectedType}
              onChange={(selected) => {
                setSelectedType(selected)
              }}
              options={documentTypes}
            />
          </Col>
          <Col xs={12}>
            <Label className="form-label mb-0">{t("Files")}</Label>
            <Input
              type='file'
              multiple={true}
              placeholder="Upload document..."
              onChange={(e) => {
                unloadedFile.current = e.target.files
              }}
            >
            </Input>
          </Col>
          <Col xs={12} className="hstack justify-content-end">
            <Button type="submit" color="primary" className="btn ms-auto mt-4">{t("Submit")}</Button>
          </Col>
        </Row>
      </Form>
    </>
	)
}

export default UploadDocument
